import { FC, ImgHTMLAttributes, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const ImageElement = styled.img`
  display: block;
`;

export const Image: FC<ImageProps> = ({ ...rest }) => {
  const ref = useRef<HTMLImageElement>(null);

  const { src } = rest;

  useLayoutEffect(() => {
    const imageElement = ref.current;
    if (!imageElement) return;

    imageElement.src = src || '';
    return () => {
      imageElement.src = '';
    };
  }, [src]);

  return <ImageElement ref={ref} {...rest} />;
};
