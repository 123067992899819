import { Size } from '@app/interfaces/size.interface';
import { useLayoutEffect, useRef, useState } from 'react';

export const useElementSize = () => {
  const [size, setSize] = useState<Size | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const el = ref.current;
    if (!el) return;

    const resizeObserver = new ResizeObserver((event) => {
      const width = event[0].contentBoxSize[0].inlineSize;
      const height = event[0].contentBoxSize[0].blockSize;
      setSize({ width, height });
    });

    resizeObserver.observe(el);

    return () => resizeObserver.unobserve(el);
  }, []);

  return [ref, size] as const;
};
