import { keyframes } from 'styled-components';

export const bottomSlideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;
