import { bottomSlideIn } from '@app/styles/animations/bottom-slide-in';
import styled from 'styled-components';

export const MobileActionsModalWrapper = styled.div`
  padding: 10px;
  display: grid;
  grid-template-rows: 1fr max-content;
  grid-template-columns: 300px;
  justify-content: center;
  gap: 30px;

  box-sizing: border-box;

  position: fixed;
  background: rgba(255, 255, 255, 0.98);
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 380px;
  z-index: 10;

  animation: ${bottomSlideIn} 0.3s ease-out;
  animation-fill-mode: both;
`;

export const MobileActionsModalContent = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 25px;
`;
