import { FC, VideoHTMLAttributes, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  isPlaying?: boolean;
}

const VideoElement = styled.video`
  display: block;
`;

export const Video: FC<VideoProps> = ({ src, isPlaying = true, ...rest }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useLayoutEffect(() => {
    const videoElement = ref.current;
    if (!videoElement) return;
    videoElement.src = src + '#t=0.01';

    return () => {
      videoElement.pause();
      videoElement.src = '';
    };
  }, [src]);

  useEffect(() => {
    const videoElement = ref.current;
    if (!videoElement || !isLoaded) return;
    if (isPlaying) {
      if (videoElement.paused) videoElement.play();
    } else {
      if (!videoElement.paused) videoElement.pause();
      videoElement.currentTime = 0;
    }
  }, [isPlaying, isLoaded]);

  return (
    <VideoElement
      ref={ref}
      controls
      loop
      preload="metadata"
      playsInline
      {...rest}
      onLoadedMetadata={(e) => {
        setIsLoaded(true);
        rest.onLoadedMetadata?.(e);
      }}
    />
  );
};
