import { useEffect, useState } from 'react';
import { BreakpointType, breakpoints } from '@app/styles/breakpoints';

export const useBreakpoint = (breakpointType: BreakpointType) => {
  const media = `(min-width: ${breakpoints[breakpointType]})`;
  const [matches, setMatches] = useState(window.matchMedia(media).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(media);
    const handler = ({ matches }: MediaQueryListEvent) => setMatches(matches);

    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, [media]);

  return matches;
};
