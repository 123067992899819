import axios from 'axios';
import { useEffect, useState } from 'react';

export const useFileDownload = (url: string, filename: string) => {
  const [isError, setIsError] = useState(false);
  const [file, setFile] = useState<null | File>(null);

  useEffect(() => {
    const controller = new AbortController();
    axios
      .get(url, {
        responseType: 'blob',
        signal: controller.signal,
      })
      .then(({ data }) => {
        const type = filename.endsWith('.mp4') ? 'video/mp4' : 'image/jpeg';
        setFile(new File([data], filename, { type }));
      })
      .catch((e: Error) => {
        if (e.name !== 'CanceledError') setIsError(true);
      });

    return () => controller.abort();
  }, [url, filename]);

  return { file, isError };
};
