import { FC, useCallback } from 'react';
import { Button } from '@app/components/button';
import { MobileShareButtonsWrapper } from './mobile-share-buttons.styles';
import { share } from '@app/utils/share.util';
import { ViewCapture } from '@app/feautres/capture/views/captures/interfaces';
import { getCaptureUrl } from '@app/utils/links.util';
import { useGa4Stats } from '@app/feautres/stats/contexts/ga4-stats.context';
import { useMobileFileSharing } from './use-mobile-file-sharing';

interface MobileShareButtonsProps {
  capture: ViewCapture;
  onFinish: () => void;
}

export const MobileShareButtons: FC<MobileShareButtonsProps> = ({ capture, onFinish }) => {
  const ga4Stats = useGa4Stats();

  const shareLink = () => {
    share({ url: getCaptureUrl(capture.shortId) });
    setTimeout(() => {
      ga4Stats.register('shareLink');
      onFinish();
    }, 500);
  };

  const onDownloaded = useCallback(() => {
    ga4Stats.register('download');
    onFinish();
  }, [ga4Stats, onFinish]);

  const onShared = useCallback(() => {
    ga4Stats.register('shareFile');
    onFinish();
  }, [ga4Stats, onFinish]);

  const sharing = useMobileFileSharing({ capture, onDownloaded, onShared, onError: onFinish });

  if (sharing.isNotAllowed)
    return (
      <MobileShareButtonsWrapper>
        <Button icon={sharing.state === 'download' ? 'download' : 'share'} onClick={sharing.retry}>
          {sharing.state === 'download' ? 'Click here to download' : 'Click here to share'}
        </Button>
      </MobileShareButtonsWrapper>
    );

  return (
    <MobileShareButtonsWrapper>
      <Button icon="link" onClick={shareLink}>
        Share link
      </Button>
      <Button icon="share" onClick={sharing.shareFile} isLoading={sharing.state === 'share'}>
        Share file
      </Button>
      <Button icon="download" onClick={sharing.download} isLoading={sharing.state === 'download'}>
        Download
      </Button>
    </MobileShareButtonsWrapper>
  );
};
