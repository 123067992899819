import styled from 'styled-components';

export const MobileShareButtonsWrapper = styled.div`
  height: 100%;
  display: grid;
  gap: 50px;
  align-content: center;

  & > *:not(:last-child) {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 300px;
      height: 1px;
      left: 50%;
      bottom: -25px;
      background: ${({ theme }) => theme.colors.gray};
      transform: translate(-50%, -50%);
    }
  }
`;
