import { Button } from '@app/components/button';
import { FC, useCallback, useState } from 'react';
import { MobileCaptureActionsWrapper } from './mobile-capture-actions.styles';
import { MobileActionsModal } from './modal';
import { MobileShareButtons } from './share-buttons';
import { ViewCapture } from '@app/feautres/capture/views/captures/interfaces';

interface MobileCaptureActionsProps {
  capture: ViewCapture;
}

export const MobileCaptureActions: FC<MobileCaptureActionsProps> = ({ capture }) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <MobileCaptureActionsWrapper>
      <Button
        icon="shareRect"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        Choose sharing option
      </Button>
      {isOpen && (
        <MobileActionsModal onClose={close}>
          <MobileShareButtons capture={capture} onFinish={close} />
        </MobileActionsModal>
      )}
    </MobileCaptureActionsWrapper>
  );
};
