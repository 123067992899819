import { createContext, useContext } from 'react';
import { Ga4Action } from '../types/ga4-action.type';

interface Ga4StatsContext {
  register: (action: Ga4Action) => void;
}

export const Ga4StatsContext = createContext<Ga4StatsContext>({
  register: () => {},
});

export const useGa4Stats = () => useContext(Ga4StatsContext);
