import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon } from '@app/components/icon';

export const ToastsContainer = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    icon={({ type }) => <Icon name={type === 'error' ? 'error' : 'tick'} color="white" />}
  />
);
