import styled from 'styled-components';

interface CapturePreviewWrapperProps {
  $width: number;
  $height: number;
}

export const CapturePreviewWrapper = styled.div.attrs<CapturePreviewWrapperProps>(({ $width, $height }) => ({
  style: { width: `${$width}px`, height: `${$height}px` },
}))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CapturePreviewContent = styled.div<{ $visible: boolean }>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
`;
