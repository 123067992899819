import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { ViewCapture } from '../views/captures/interfaces';
import notLoadedImg from '@app/assets/not-loaded.svg';

export const useCapturePreviewUrl = (capture: ViewCapture) => {
  const fileUrl = capture.file?.path || notLoadedImg;
  const previewUrl = capture.preview?.path || fileUrl;

  const url = useBreakpoint('lg') ? fileUrl : previewUrl;

  return url;
};
