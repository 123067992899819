import { useLayoutEffect, useMemo } from 'react';

const overlayRoot = document.getElementById('overlay')!;

let count = 0;

export const usePortalContainer = () => {
  const el = useMemo(() => document.createElement('div'), []);

  useLayoutEffect(() => {
    overlayRoot.appendChild(el);
    document.body.classList.add('modalactive');
    count++;
    return () => {
      overlayRoot.removeChild(el);
      if (--count === 0) document.body.classList.remove('modalactive');
    };
  }, [el]);

  return el;
};
