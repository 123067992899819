import { FC } from 'react';
import { DesktopCaptureActions } from './desktop';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { MobileCaptureActions } from './mobile/mobile-capture-actions';
import { ViewCapture } from '../../views/captures/interfaces';

interface CaptureActionsProps {
  capture: ViewCapture;
}

export const CaptureActions: FC<CaptureActionsProps> = ({ capture }) => {
  const isDesktop = useBreakpoint('md');

  if (isDesktop) return <DesktopCaptureActions capture={capture} />;
  return <MobileCaptureActions capture={capture} />;
};
