import { FC, ReactNode } from 'react';
import { CaptureIdContext } from '../contexts/capture-id.context';

interface CaptureIdProviderProps {
  children: ReactNode;
  captureId: string;
}

export const CaptureIdProvider: FC<CaptureIdProviderProps> = ({ children, captureId }) => {
  return <CaptureIdContext.Provider value={captureId}>{children}</CaptureIdContext.Provider>;
};
