import { FC, ReactNode } from 'react';
import { GalleryIdContext } from '../contexts/gallery-id.context';

interface GalleryIdProviderProps {
  galleryId: string;
  children: ReactNode;
}

export const GalleryIdProvider: FC<GalleryIdProviderProps> = ({ children, galleryId }) => {
  return <GalleryIdContext.Provider value={galleryId}>{children}</GalleryIdContext.Provider>;
};
