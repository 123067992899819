import { FC } from 'react';
import { Button } from '@app/components/button';
import { DesktopCaptureActionsWrapper } from './desktop-capture-actions.styles';
import { ViewCapture } from '@app/feautres/capture/views/captures/interfaces';
import { useCopyCaptureLink } from '@app/feautres/capture/hooks/use-copy-capture-link';
import { useGa4Stats } from '@app/feautres/stats/contexts/ga4-stats.context';
import { useCaptureDownload } from '@app/feautres/capture/hooks/use-capture-download';

interface DesktopCaptureActionsProps {
  capture: ViewCapture;
}

export const DesktopCaptureActions: FC<DesktopCaptureActionsProps> = ({ capture }) => {
  const linkToClipboard = useCopyCaptureLink(capture);
  const ga4Stats = useGa4Stats();

  const startDownload = useCaptureDownload(capture.id);

  const download = () => {
    ga4Stats.register('download');
    startDownload();
  };

  const copyLink = async () => {
    linkToClipboard();
    ga4Stats.register('shareLink');
  };

  return (
    <DesktopCaptureActionsWrapper>
      <Button icon="share" onClick={copyLink}>
        Share link
      </Button>
      <Button icon="download" onClick={download}>
        Download
      </Button>
    </DesktopCaptureActionsWrapper>
  );
};
