import { toast } from 'react-toastify';
import { getCaptureUrl } from '@app/utils/links.util';
import { ViewCapture } from '../views/captures/interfaces';

export const useCopyCaptureLink = (capture: ViewCapture) => {
  const captureUrl = getCaptureUrl(capture.shortId);

  const copyCaptureLink = async () => {
    try {
      await navigator.clipboard.writeText(captureUrl);
      toast('Link was copied. Share with your friends!');
    } catch {
      toast('Failed to copy');
      console.error(`Failed to copy: ${captureUrl}`);
    }
  };

  return copyCaptureLink;
};
