import { useCallback } from 'react';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const generateCaptureUrlDocument = graphql(`
  mutation generateCaptureDownloadUrl($captureId: UUID!) {
    url: generateCaptureDownloadUrl(captureId: $captureId)
  }
`);

export const useCaptureDownload = (captureId: string) => {
  const [download] = useMutation(generateCaptureUrlDocument, {
    variables: { captureId },
    onError: () => {},
  });

  const startDownload = useCallback(async () => {
    return download()
      .then(({ data }) => {
        if (!data) throw new Error('Cannot get url');
        return data.url;
      })
      .then((url) => {
        document.location.href = url;
        return true;
      })
      .catch(() => {
        toast.error('Cannot download capture');
        return false;
      });
  }, [download]);

  return startDownload;
};
