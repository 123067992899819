import { IS_IOS } from '@app/constants/config.constant';
import { useCaptureDownload } from '@app/feautres/capture/hooks/use-capture-download';
import { useCaptureFilename } from '@app/feautres/capture/hooks/use-capture-filename';
import { ViewCapture } from '@app/feautres/capture/views/captures/interfaces';
import { useFileDownload } from '@app/hooks/use-file-download';
import { share } from '@app/utils/share.util';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface UseMobileFileSharing {
  capture: ViewCapture;
  onDownloaded: () => void;
  onShared: () => void;
  onError: () => void;
}

export const useMobileFileSharing = ({ capture, onDownloaded, onShared, onError }: UseMobileFileSharing) => {
  const [state, setState] = useState<null | 'download' | 'share'>(null);
  const [isNotAllowed, setIsNotAllowed] = useState(false);

  const startDownload = useCaptureDownload(capture.id);
  const downloadName = useCaptureFilename(capture);
  const { file, isError } = useFileDownload(capture.file!.path!, downloadName);

  const goShare = useCallback(async () => {
    if (isError) {
      toast.error('Cannot download capture');
      onError();
      return;
    }
    if (!file) return;
    const timeout = setTimeout(() => {
      state === 'download' ? onDownloaded() : onShared();
    }, 500);
    share({ files: [file] }).catch((err: Error) => {
      if (err.name !== 'NotAllowedError') return;
      clearTimeout(timeout);
      setIsNotAllowed(true);
    });
  }, [file, onDownloaded, onShared, onError, isError, state]);

  const goDownload = useCallback(() => {
    startDownload().then((isSuccess) => {
      isSuccess ? onDownloaded() : onError();
    });
  }, [onDownloaded, startDownload, onError]);

  useEffect(() => {
    if (state === 'share') goShare();
    else if (state === 'download' && IS_IOS) goShare();
    else if (state === 'download' && !IS_IOS) goDownload();
  }, [state, goDownload, goShare]);

  const download = useCallback(() => setState('download'), []);
  const shareFile = useCallback(() => setState('share'), []);

  return { shareFile, download, isNotAllowed, state, retry: goShare };
};
