import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useOutsideClick } from '@app/hooks/use-outside-click';
import { usePortalContainer } from '@app/hooks/use-portal-container';
import { Button } from '@app/components/button';
import { MobileActionsModalContent, MobileActionsModalWrapper } from './mobile-share-modal.styles';

interface MobileActionsModalProps {
  children: ReactNode;
  onClose: () => void;
}

export const MobileActionsModal: FC<MobileActionsModalProps> = ({ children, onClose }) => {
  const el = usePortalContainer();
  const ref = useOutsideClick(onClose);

  return createPortal(
    <MobileActionsModalWrapper ref={ref} onClick={(e) => e.stopPropagation()}>
      <MobileActionsModalContent>{children}</MobileActionsModalContent>
      <Button kind="gray" onClick={onClose} style={{ marginLeft: '25px', marginRight: '25px' }}>
        Cancel
      </Button>
    </MobileActionsModalWrapper>,
    el,
  );
};
