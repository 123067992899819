import { FC, ReactEventHandler, VideoHTMLAttributes, useMemo, useState } from 'react';
import { CapturePreviewContent, CapturePreviewWrapper } from './capture-preview.styles';
import { Video } from '@app/components/video';
import { ViewCapture } from '../../views/captures/interfaces';
import { Image } from '@app/components/image';
import { ErrorView } from '@app/views/error';
import { Size } from '@app/interfaces/size.interface';
import { Spinner } from '@app/layout/spinner';
import { useCapturePreviewUrl } from '../../hooks/use-capture-preview-url';
import { Color } from '@app/styled';

interface CapturePreviewProps {
  maxWidth: number;
  maxHeight: number;
  capture: ViewCapture;
  onLoad?: (targetSize: Size) => void;
  videoAttrs?: VideoHTMLAttributes<HTMLVideoElement> & { isPlaying?: boolean };
  spinnerColor?: Color;
}

export const CapturePreview: FC<CapturePreviewProps> = ({ capture, maxWidth, maxHeight, videoAttrs, onLoad, spinnerColor }) => {
  const [targetSize, setTargetSize] = useState<Size | null>(null);
  const [isError, setIsError] = useState(false);

  const size = useMemo(() => {
    if (!targetSize) return null;
    const targetProportions = targetSize.height / targetSize.width;
    const proportions = maxHeight / maxWidth;
    if (proportions > targetProportions) {
      const targetWidth = targetSize.width > maxWidth ? maxWidth : targetSize.width;
      const targetHeight = targetWidth * targetProportions;
      return { width: targetWidth, height: targetHeight };
    } else {
      const targetHeight = targetSize.height > maxHeight ? maxHeight : targetSize.height;
      const targetWidth = targetHeight / targetProportions;
      return { width: targetWidth, height: targetHeight };
    }
  }, [targetSize, maxWidth, maxHeight]);

  const handleMetadata: ReactEventHandler<HTMLVideoElement> = (e) => {
    const { videoHeight, videoWidth } = e.currentTarget;
    setTargetSize({ width: videoWidth, height: videoHeight });
    onLoad?.({ width: videoWidth, height: videoHeight });
  };

  const handleImgLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
    setTargetSize({ width: naturalWidth, height: naturalHeight });
    onLoad?.({ width: naturalWidth, height: naturalHeight });
  };

  const url = useCapturePreviewUrl(capture);

  const handleError = () => setIsError(true);

  return (
    <CapturePreviewWrapper $width={size?.width || maxWidth} $height={size?.height || maxHeight}>
      {isError && <ErrorView error="Cannot load media" onRetry={() => setIsError(false)} />}
      {!size && !isError && <Spinner color={spinnerColor} />}
      {!isError && (
        <CapturePreviewContent $visible={!!size}>
          {url.endsWith('mp4') ? (
            <Video
              onError={handleError}
              src={url}
              style={{ width: `${size?.width}px`, minHeight: `${size?.height}px` }}
              onLoadedMetadata={handleMetadata}
              isPlaying={true}
              {...videoAttrs}
            />
          ) : (
            <Image onError={handleError} onLoad={handleImgLoad} src={url} {...size} alt="preview" />
          )}
        </CapturePreviewContent>
      )}
    </CapturePreviewWrapper>
  );
};
